<template>
  <div>
    <div class="image-slides-gallery d-lg-none">
      <vueper-slides :autoplay="checkAutoplay()" :arrows="false" :bullets="true" fixed-height="300px">
        <vueper-slide v-for="(slide, i) in slides" :key="i" :image="slide.image" />
      </vueper-slides>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 mb-3 mb-lg-0">
          <div class="main-title">
            <BackButton />
            <h1 v-html="title" class="title"></h1>
            <bookmark-button v-if="false" class="btn btn-icon-only btn-card btn-bookmark desktop-bookmark-btn" :parentClass="'btn-card'" :content="content"/>
            <share-button v-if="collection.collection_type!=='user'"/>
            <div class="del-edit">
              <button class="btn btn-profile icon-before mr20 new-btns" @click="showEditCollection" v-if="canEditCollection">
                <i class="material-icons new-icons" aria-hidden="true">edit</i>
                <span class="btn-text">Bearbeiten</span>
              </button>

              <button class="btn btn-profile icon-before new-btns" @click="showDeleteCollectionModal=true" v-if="canDeleteCollection">
                <i class="material-icons new-icons" aria-hidden="true">delete</i>
                <span class="btn-text">Löschen</span>
              </button>
            </div>
            <a :href="linkTo" class="institution-link" v-if="this.institutionName!==null"><h2 class="institution-name">{{institutionName}}</h2></a>
          </div>

          <div class="mobile-bookmark-btn" v-if="false">
            <bookmark-button class="btn btn-icon-only btn-card btn-bookmark" :parentClass="'btn-card'"/>
          </div>

          <p class="info" v-html="stringifiedCategories"></p>

          <div class="gallery-desktop">

            <vueper-slides v-if="showSlides" class="no-shadow" :autoplay="checkAutoplay()" :visible-slides="visibleSlides" :gap="1" :dragging-distance="70" :arrows="true":bullets="false" fixed-height="300px">
              <vueper-slide style="border-radius: 10px!important;" v-for="(slide, i) in slides" :key="i" :image="slide.image" />
            </vueper-slides>

          </div>

        </div>
      </div>
    </div>
    <delete-collection-modal v-if="this.collection!=null && showDeleteCollectionModal" :collection="this.collection" @closeModal="showDeleteCollectionModal=false"></delete-collection-modal>
  </div>
</template>

<script>
  import 'vueperslides/dist/vueperslides.css';
  import { VueperSlides, VueperSlide } from 'vueperslides';
  import checkPermission from '@/utils/permission';
  import DeleteCollectionModal from '@/components/modal/deleteCollection.vue';
  import { json_decode, getFirstFieldValue } from '@/utils/helpers';
  import { getCategoryPlaceholderImage } from '@/utils/tripmeister-helpers';

  export default {
    name: 'ReiseplanDetailBanner',
    components: {
      ShareButton: () => import('@/components/controls/ShareButton'),
      BookmarkButton: () => import('@/components/controls/BookmarkButton'),
      BackButton: () => import('@/components/controls/BackButton'),
      VueperSlides,
      VueperSlide,
      DeleteCollectionModal
    },
    props: {
      collection: {
        type: Object,
      default: null
      },
      contents: {
        type: Array,
      default: null
      },
      institutionId: {
        type: Number,
      default: null
      },
      institutionName: {
        type: String,
      default: null
      },
      object: {
        type: Boolean,
      default: false
      },
    },
    data() {
      return {
        showDeleteCollectionModal: false,
      }
    },
    computed:{
      slides() {
        var images = [];
        if(this.collection!==null){
          for(var i = 0; i < this.collection.images.length; i++){
            var image = json_decode(this.collection.images[i]);
            if(typeof image === "object"){
              if (image.path.indexOf('https') !== -1) {
                images.push({image:image.path,class:'teaser-img'});
              }
              else {
                images.push({image:this.$backendUrl+image.path,class:'teaser-img'});
              }
            }
            else{
              if (image.indexOf('https') !== -1) {
                images.push({image:image,class:'teaser-img'});
              }
              else {
                images.push({image: this.$backendUrl+image,class:'teaser-img'});
              }
            }
          }
          if(images.length == 0) {
            images.push({image: getCategoryPlaceholderImage("bla"),class:'teaser-img'});
          }
        }
        return images;
      },
      showSlides(){
        if(this.slides.length===0){
          return false;
        }
        return true;
      },
      visibleSlides(){
        if(this.slides.length>=3){
          return 3;
        }
        return this.slides.length;
      },
      title(){
        if(this.collection!==null){
          return this.collection.name;
        }
        return null;
      },
      categories(){
        if(this.contents!==null){
          var categories = [];
          for(var i = 0; i < this.contents.length; i++){
            categories.push(getFirstFieldValue(this.contents[i],'kategorien'));
          }
          var uniqueCategories = [...new Set(categories)];
          return uniqueCategories;
        }
        return null;
      },
      stringifiedCategories(){
        if(this.contents!==null){
          return this.categories.join(', ');
        }
        return null;
      },
      canShareCollection(){
        if(this.checkPermission({elem: this.collection ,permission: 'shareCollections'})){
          return true;
        }
        return false;
      },
      canEditCollection(){
        if(this.checkPermission({elem: this.collection ,permission: 'editCollections'})){
          return true;
        }
        return false;
      },
      canDeleteCollection(){
        if(this.checkPermission({elem: this.collection ,permission: 'deleteCollections'})){
          return true;
        }
        return false;
      },
      user(){
        return this.$store.state.user;
      },
      linkTo(){
        return "/#/location/"+this.institutionId;
      },
    },
    methods: {
      checkPermission,
      json_decode,
      getFirstFieldValue,
      getCategoryPlaceholderImage,
      showEditCollection(){
       this.$router.push({ name: 'TripEdit', params: {id: this.$route.params.id } });
     },
     checkAutoplay(){
      if(this.slides.length>1){
        return true;
      }
      return false;
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .page-content.detailed .info {
    color: #E5321B;
    font-size: 14px;
    font-weight: bold;
    text-transform:uppercase;
  }

  .page-content.detailed .main-title h1 {
   max-width: calc(100% - 80px);
 }

 .page-content.detailed .main-title{
  @media (max-width: 760px) {
    top:-120px;
  }

  @media (max-width: 510px) {
    top:-150px;
  }
}

.mobile-bookmark-btn{
  display:none;

  @media (max-width: 760px) {
    display: block;
    margin-bottom: 0;
    position: relative;
    top: -100px;
    z-index: 100;
    height: 0;

    .btn-bookmark{
      margin-right: 10px;
      position: absolute;
      bottom: -120px;
      right: 0;
    }

  }
}

.institution-name {
  font-size: 20px !important;
  text-decoration: underline;

  &:hover {
    color: #E5321B;
  }
}

.desktop-bookmark-btn{
  @media (max-width: 760px) {
    display:none;
  }
}

.new-icons{
  vertical-align: middle;
  padding-right: 5px;

  @media (max-width: 991px) {
    text-align: center;
    border: 1px solid #000000;
    border-radius: 50px;
    height: 40px;
    width: 40px;
    display: inline-block;
    line-height: 36px;
    text-align: center;
    font-size: 24px;
    color: #000000;
  }
}

.new-btns{
  width: 200px;
  @media (max-width: 991px) {
    position: absolute;
    background: none;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.35);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    right: 4px;
    top: 80px;
    padding: 5px;
    border-color: white;

    &:first-child {
      right: 48px;
    }
    &:hover {
      border-color:#E5321B;
      color: #E5321B;
    }
  }

  @media (max-width: 991px) and (min-width: 511px) {
    top: 60px;
  }

  @media (max-width: 991px) and (min-width: 761px) {
    top: 40px;
  }

  & i {
    @media (max-width: 991px) { 
      color: white;
      border: none;
    }
  }
}

.del-edit{
  display: inline;
  float:right;

  @media (max-width: 991px) {

  }
}

.btn-text{
  @media (max-width: 991px) {
    display: none;
  }
}

.main-title {
  margin-bottom: 30px !important;
}

.title {
  word-break: break-word;
}

.btn-back {
  @media(max-width:991px){
    margin-bottom: 100px !important;
  }
}

</style>

<style lang="scss">

  .gallery-desktop{
    .vueperslide, .vueperslide__content-wrapper{
      border-radius: 10px;
    }
  }


</style>